import {storage, auth, db} from "../../../firebase/firebase";
import {useEffect, useState} from "react";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './style.css'
import {ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export const Profile = ({edit}) => {

    const data = useSelector((state) => state.data.user_data)
    const [profileimg, setprofileImg] = useState('/assets/images/avatar-default.png')
    const [dob, setDob] = useState(null);
    const [displayName, setDisplayName] = useState('')
    const [gender, setGender] = useState('M')
    const [height, setHeight] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('NM')
    const [children, setChildren] = useState('0')
    const [bio, setBio] = useState('')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    useEffect(() => {

        if (edit) {
            setprofileImg(data['profile']['profileImg'])
            setDisplayName(data['profile']['display_name'])
            setDob(new Date(data['profile']['dob'] * 1000))
            setGender(data['profile']['gender'])
            setHeight(data['profile']['height'])
            setMaritalStatus(data['profile']['marital_status'])
            setChildren(data['profile']['children'])
            setBio(data['profile']['bio'])
        }

    }, [])

    var getFileBlob = function (url, cb) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.addEventListener('load', function() {
            cb(xhr.response);
        });
        xhr.send();
    };


    function uploadProfileImage() {

        return new Promise((resolve, reject) => {
            if (('profile' in data) && profileimg === data['profile']['profileImg']) {
                resolve(profileimg)
            }
            const profileRef = ref(storage, auth.currentUser.uid)
            getFileBlob(profileimg, blob => {
                uploadBytes(profileRef, blob).then((snapshot) => {
                    getDownloadURL(profileRef)
                        .then((url) => {
                            // Do something with the URL ...
                            return resolve(url)
                        })
                }).catch((err) => {
                    return reject(err.code)
                })
            })
        })
    }

    function getAge(DOB) {
        var today = new Date();
        var birthDate = new Date(DOB * 1000);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }


    const completeProfile = () => {

        //Here perform all checks

        if (profileimg === '/assets/images/avatar-default.png') {
            alert('Please upload a profile image')
            return
        }

        if (displayName === '') {
            alert('Please enter a display name')
            return;
        }

        if (displayName.length > 12) {
            alert('Max display name length is 12 characters')
            return;
        }

        if (dob === null) {
            alert('Please provide a date of birth')
            return;
        }

        var dateOfBirth = new Date(dob)
        dateOfBirth = dateOfBirth.getTime() / 1000

        if (getAge(dateOfBirth) < 18) {
            alert('You must be 18 to use Guursamo')
            return;
        }

        if (bio === '') {
            alert('Please provide a bio')
            return;
        }

        setLoading(true)



        uploadProfileImage().then((url) => {
            console.log(url)
            //    Then upload data to "profile"
            setDoc(doc(db, 'users', auth.currentUser.uid), {
                'profile': {
                    'display_name': displayName,
                    'profileImg': url,
                    'dob': dateOfBirth,
                    'gender': gender,
                    'height': height,
                    'marital_status': maritalStatus,
                    'children': children,
                    'bio': bio,
                    'uid': auth.currentUser.uid
                }
            }, {merge: true}).then(() => {
                if (edit) {
                    navigate('/account')
                } else {
                    navigate('/interests')
                }
            }).catch((err) => {
                alert(err.code)
                setLoading(false)
            })

        }).catch((err) => {
            alert(`Error: ${err}`)
            setLoading(false)
        })

    }

    return (<div className="card card-flush h-lg-100" id="kt_contacts_main">
            {/*begin::Card header*/}

            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body pt-5">
                {/*begin::Form*/}
                <div className="form fv-plugins-bootstrap5 fv-plugins-framework" >
                    {/*begin::Input group*/}
                    <div className="mb-7">
                        {/*begin::Label*/}

                        {/*end::Label*/}
                        {/*begin::Image input wrapper*/}

                        {/*Here add profile picture upload*/}
                        <div className="image-input " style={{margin: 'auto', display: 'block'}}>
                            {/*begin::Preview existing avatar*/}
                            <img src={profileimg} style={{boxShadow: '3px 0rem 1rem 0rem rgb(0 0 0 / 6%)',
                                border: '1px solid #cdcdcd', width: '150px', height: '150px', borderRadius: '75px',
                                objectFit: 'cover', margin: 'auto', display: 'block'}}/>
                            {/*end::Preview existing avatar*/}
                            {/*begin::Edit*/}
                            {/*end::Edit*/}
                            {/*begin::Cancel*/}
                            {/*end::Remove*/}
                        </div>

                                <div className={'pt-5'}/>
                                <button onClick={() => document.getElementById('avatar').click()}>
                                    {profileimg === '/assets/images/avatar-default.png' ? ('Upload profile picture')
                                        : ('Change profile picture')}
                                </button>
                                <input id={'avatar'} onChange={(img) =>
                                           setprofileImg(window.URL.createObjectURL(img.target.files[0]))} accept=".png, .jpg, .jpeg" type="file" style={{display: 'none'}} />
                            {/*begin::Inputs*/}


                        {/*end::Image input wrapper*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-7 fv-plugins-icon-container">
                        {/*begin::Label*/}
                        <label className="fs-6 fw-semibold form-label mt-3">
                            <span className="required">Display Name</span>
                            <i className="fas fa-exclamation-circle ms-1 fs-7" />
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <input type="text" value={displayName} onChange={(val) => setDisplayName(val.target.value)} className="form-control form-control-solid" placeholder={'Aasha'} />
                        {/*end::Input*/}
                        <div className="fv-plugins-message-container invalid-feedback" /></div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-7">
                        {/*begin::Label*/}
                        <label className="fs-6 fw-semibold form-label mt-3">
                            <span className="required">Date of birth (MM/DD/YYYY)</span>
                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Enter the contact's company name (optional)." data-bs-original-title="Enter the contact's company name (optional)." data-kt-initialized={1} />
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label=""
                                disableOpenPicker={true}
                                className={'form-control form-control-solid borderNone'}
                                value={dob}
                                onChange={(newValue) => {
                                    setDob(newValue);
                                }}

                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>


                        {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Row*/}
                    <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                        {/*begin::Col*/}
                        <div className="col">
                            {/*begin::Input group*/}
                            <div className="fv-row mb-7 fv-plugins-icon-container">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-semibold form-label mt-3">
                                    <span className="required">Gender</span>
                                    <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Enter the contact's email." data-bs-original-title="Enter the contact's email." data-kt-initialized={1} />
                                </label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <div className="w-100">
                                    <div className="form-floating border rounded">
                                        {/*begin::Select2*/}
                                        <select className="form-select form-select-solid lh-1 py-3 select2-hidden-accessible"
                                                value={gender}
                                                onChange={(val) => setGender(val.target.value)}
                                        >
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>

                                        </select>
                                    </div>
                                </div>
                                {/*end::Input*/}
                                <div className="fv-plugins-message-container invalid-feedback" /></div>
                            {/*end::Input group*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col">
                            {/*begin::Input group*/}
                            <div className="fv-row mb-7">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-semibold form-label mt-3">
                                    <span>Height (cm)</span>
                                    <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Enter the contact's phone number (optional)." data-bs-original-title="Enter the contact's phone number (optional)." data-kt-initialized={1} />
                                </label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <input type="number"
                                        value={height} onChange={(val) => isNumeric(val.target.value) ?
                                    (val.target.value.length <= 3 ?
                                    (setHeight(val.target.value)) :
                                    (setHeight(height))) : (setHeight(''))}

                                       className="form-control form-control-solid" />
                                {/*end::Input*/}
                            </div>
                            {/*end::Input group*/}
                        </div>
                        {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Row*/}
                    <div className="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                        {/*begin::Col*/}
                        <div className="col">
                            {/*begin::Input group*/}
                            <div className="fv-row mb-7 fv-plugins-icon-container">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-semibold form-label mt-3">
                                    <span className="required">Marital Status</span>
                                    <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Enter the contact's email." data-bs-original-title="Enter the contact's email." data-kt-initialized={1} />
                                </label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <div className="w-100">
                                    <div className="form-floating border rounded">
                                        {/*begin::Select2*/}
                                        <select className="form-select form-select-solid lh-1 py-3 select2-hidden-accessible"
                                            value={maritalStatus} onChange={(val) => setMaritalStatus(val.target.value)}
                                        >
                                            <option value="NM" data-kt-select2-country="assets/media/flags/afghanistan.svg">Never married</option>
                                            <option value="W" data-kt-select2-country="assets/media/flags/aland-islands.svg">Widowed</option>
                                            <option value="S" data-kt-select2-country="assets/media/flags/aland-islands.svg">Seperated</option>
                                            <option value="D" data-kt-select2-country="assets/media/flags/aland-islands.svg">Divorced</option>

                                        </select>
                                    </div>
                                </div>
                                {/*end::Input*/}
                                <div className="fv-plugins-message-container invalid-feedback" /></div>
                            {/*end::Input group*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col">
                            {/*begin::Input group*/}
                            <div className="fv-row mb-7 fv-plugins-icon-container">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-semibold form-label mt-3">
                                    <span className="required">Children</span>
                                    <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Enter the contact's email." data-bs-original-title="Enter the contact's email." data-kt-initialized={1} />
                                </label>
                                {/*end::Label*/}
                                {/*begin::Input*/}
                                <div className="w-100">
                                    <div className="form-floating border rounded">
                                        {/*begin::Select2*/}
                                        <select className="form-select form-select-solid lh-1 py-3 select2-hidden-accessible"
                                            onChange={(val) => setChildren(val.target.value)}
                                            value={children}
                                        >
                                            <option value="0" data-kt-select2-country="assets/media/flags/afghanistan.svg">0</option>
                                            <option value="1" data-kt-select2-country="assets/media/flags/aland-islands.svg">1</option>
                                            <option value="2" data-kt-select2-country="assets/media/flags/aland-islands.svg">2</option>
                                            <option value="3" data-kt-select2-country="assets/media/flags/aland-islands.svg">3+</option>

                                        </select>
                                    </div>
                                </div>
                                {/*end::Input*/}
                                <div className="fv-plugins-message-container invalid-feedback" /></div>
                            {/*end::Input group*/}
                        </div>
                        {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-7">
                        {/*begin::Label*/}
                        <label className="fs-6 fw-semibold form-label mt-3">
                            <span>Bio</span>
                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" aria-label="Enter any additional notes about the contact (optional)." data-bs-original-title="Enter any additional notes about the contact (optional)." data-kt-initialized={1} />
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <textarea className="form-control form-control-solid"
                                  style={{minHeight: '200px'}}
                                  value={bio}
                                  onChange={(val) => setBio(val.target.value)}
                                  maxLength={500}
                                  placeholder={'Write about yourself'} name="notes" defaultValue={""} />
                        {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Separator*/}
                    <div className="separator mb-6" />
                    {/*end::Separator*/}
                    {/*begin::Action buttons*/}
                    <div className="d-flex justify-content-end">
                        {/*begin::Button*/}

                        {/*end::Button*/}
                        {/*begin::Button*/}
                        {loading ? (<span className="indicator-progress" style={{display: 'block'}}>Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>) : (<button type="button" onClick={() => completeProfile()} className="btn btn-primary" style={{height: '50px'}}>
                            <span className="indicator-label">{edit ? ('Save') : ('Next')}</span>
                        </button>)}


                        {/*end::Button*/}
                    </div>
                    {/*end::Action buttons*/}
                </div>
                {/*end::Form*/}
            </div>
            {/*end::Card body*/}
        </div>
    )

}

function isNumeric(value) {
    return /^-?\d+$/.test(value);
}
