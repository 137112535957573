import React, {useState, useEffect, useRef, useMemo} from "react";
import TinderCard from "react-tinder-card";
import "./SwipeCards.css";
import "./SwipeButtons.css"
import {
    collection,
    query,
    where,
    orderBy,
    limit,
    getDocs,
    startAt,
    getDoc,
    doc,
    setDoc
} from "firebase/firestore";
import {db, auth} from "../firebase/firebase";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {setMatched, setProfile} from "../redux/slices/matched";
import {setPerson, setShown} from "../redux/slices/profile";
import {ProfileComponent} from "./profile";
import {Player} from "@lottiefiles/react-lottie-player";
import Search from "../animations/search.json";

export const SwipeCards = () => {
    const [people, setPeople] = useState([]);
    const [seenPeople, setSeenPeople] = useState([])
    const [swipes, setSwipes] = useState(['ADS'])
    const [swipesLoaded, setSwipesLoaded] = useState(false)
    const base_url = process.env.REACT_APP_BASE_URL
    const dispatch = useDispatch()


    const cardRef = useRef([]);


    const data = useSelector((state) => state.data.user_data)

        function distance(lat1, lon1, lat2, lon2, unit) {
            if ((lat1 === lat2) && (lon1 === lon2)) {
                return 0;
            }
            else {
                var radlat1 = Math.PI * lat1/180;
                var radlat2 = Math.PI * lat2/180;
                var theta = lon1-lon2;
                var radtheta = Math.PI * theta/180;
                var dist = Math.sin(radlat1) * Math.sin(radlat2)
                    + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
                if (dist > 1) {
                    dist = 1;
                }
                dist = Math.acos(dist);
                dist = dist * 180/Math.PI;
                dist = dist * 60 * 1.1515;
                if (unit==="K") { dist = dist * 1.609344 }
                if (unit==="N") { dist = dist * 0.8684 }
                return dist;
            }
    }


    const RunBatches = async () => {

        var limitNum = 4
        var startArr = 0

        if (seenPeople.length > 0) {
            startArr = seenPeople[seenPeople.length-1]
        }

        const collectionPath = collection(db, "users")

        const q = query(collectionPath,
            where('profile_complete', '==', true),
            where('profile.gender', '==',
                data['profile']['gender'] === 'M' ? ('F') : ('M')),
            orderBy('geohash', 'desc'),
            limit(limitNum)
        )

        const qR = query(collectionPath,
            where('profile_complete', '==', true),
            where('profile.gender', '==',
                data['profile']['gender'] === 'M' ? ('F') : ('M')),
            orderBy('geohash', 'desc'),
            startAt(startArr),
            limit(limitNum)
        )

        const querySnapshot = await getDocs(startArr === 0 ? (q) : (qR))

            if (!querySnapshot.empty) {

                querySnapshot.docs.map((docData, index) => {

                    console.log(docData.id)

                    setSeenPeople(prevState => [...prevState, docData])

                    if (!(swipes.includes(docData.id))) {
                        const finalData = docData.data()
                        finalData['distance'] = distance(data['lat'], data['long'],
                            finalData['lat'], finalData['long'], 'K')

                        setPeople(prevState => [finalData, ...prevState])
                    }

                    if (index === 3) {
                        setPeople(prevState => [...prevState])
                    }

                });

                if (querySnapshot.docs.length < limitNum) {
                    setPeople(null)
                }

            } else {
                setPeople(null)
            }

    }


    useEffect(() => {

      if (swipesLoaded) {
          if (people && people.length === 0) {
              // fetchNewSwipes().then(r => console.log(r))
              RunBatches()
          }
      }
    }, [swipesLoaded, people]);



    useEffect( () => {

    fetchSwipes()

    }, [])


    const fetchSwipes = () => {

      auth.currentUser.getIdToken().then((token) => {
          fetch(`${base_url}/swipes`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  'Authorization': `Bearer ${token}`
              })

          }).then((response) => {
              response.json().then((JSONdata) => {
                  if (response.status === 200) {
                      setSwipes(JSONdata['swipes'])
                      setSwipesLoaded(true)

                  } else {
                      alert('An unknown error occurred, please try again   :::Error Code:32')
                      setSwipesLoaded(true)
                  }
              })
          })
      })
    }



    const swipe = async (dir) => {
        await cardRef[people[people.length-1]['profile']['uid']].swipe(dir) // Swipe the card!
    }

    const addSwipeDb = async (person_uid) => {
        await setDoc(doc(db, `users/${auth.currentUser.uid}/swipes/${person_uid}`), {
            seen: true
        }, {merge: true})
    }

    const updateLocal = (person_uid) => {

        //Need to remove from people state and add to swipes array
        setSwipes(prevState => [...prevState, person_uid])
        setPeople(people.slice(0, -1))

    }

    const swipedRight = (person) => {
        getDoc(doc(db, `likes/${person.profile.uid}/my_likes/${auth.currentUser.uid}`)).then(async (found) => {
            if (found.exists()) {
                //    We have a match
                //    Then call match api (remember to add swiped to persons subcollection)
                dispatch(setProfile(person.profile.profileImg))
                dispatch(setMatched(true))

                auth.currentUser.getIdToken().then((token) => {
                    fetch(`${base_url}/matched`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            'Authorization': `Bearer ${token}`,
                            'person': person
                        })

                    }).then((response) => {
                        response.json().then(() => {
                            if (response.status === 200) {
                                updateLocal(person.profile.uid)
                            } else {
                                alert('An unknown error occurred, please try again   :::Error Code:33')
                            }
                        })
                    })
                })

            } else {
                await setDoc(doc(db, `likes/${auth.currentUser.uid}/my_likes/${person.profile.uid}`), {
                    liked: true
                }, {merge: true})
                updateLocal(person.profile.uid)
            }
        })
    }

    const didSwipe = async (dir, person) => {

        const person_uid = person.profile.uid
        await addSwipeDb(person_uid)

        if (dir === 'right') {
            swipedRight(person)
        } else {
           // Do nothing but update local
            updateLocal(person_uid)
        }
    }

    const showProfile = () => {

        dispatch(setPerson(people[people.length-1]))
        dispatch(setShown(true))
    }

    const personShown = useSelector(state => state.profile.isShown)

    if (personShown) {
        return <ProfileComponent/>
    } else {
        return (
            <div>

                {!swipesLoaded || people !== null && people.length === 0 ? (<div style={{marginTop: '10vh'}}>
                        <Player
                            autoplay
                            loop
                            src={Search}
                            style={{ height: '300px', width: '300px' }}
                        >
                        </Player>
                        <span style={{textAlign: 'center',
                            display: 'block', margin: 'auto', marginTop: '20px'}}>Searching for new people</span>

                    </div>) :
                    (<div className="tinderCards__cardContainer">

                        {people === null ? (<div style={{marginTop: '10vh'}}>
                            <span>Reached the end</span>
                            <button onClick={() => setPeople([])}>Search for new people</button>

                        </div>) : (people.map((person) => (
                                <TinderCard
                                    className="swipe"
                                    ref={el => cardRef[person.profile.uid] = el}
                                    key={person.profile.uid}
                                    preventSwipe={["up", "down"]}
                                    swipeThreshold={100}
                                    swipeRequirementType={'position'}
                                    onSwipe={(dir) => didSwipe(dir, person)}
                                >
                                    <div
                                        style={{ background: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .1) 40%, rgba(255, 255, 255, .5) 75%, rgba(231, 73, 149, 1) 100%), url(${person.profile.profileImg})`, backgroundSize: 'cover' }}
                                        className="cardTinder">
                                        <a onClick={() => showProfile()} onTouchStart={() => showProfile()} className={'viewButton'}>View profile</a>
                                        <div style={{position: 'absolute', bottom: '10px', maxWidth: '70%'}}>
                                            <span style={{fontSize: '18px', fontWeight: '600', color: 'white'}}>
                                                {person.profile.display_name}, {getAge(person.profile.dob)}</span>
                                            <br/>
                                            {/*<span style={{color: 'white'}}>{Math.round(person.distance)} Miles away</span>*/}
                                            {/*<br/>*/}
                                            <span className={'bio'} style={{color: 'white'}}>{person.profile.bio}</span>

                                        </div>

                                    </div>
                                </TinderCard>
                            ))
                        )}

                        {people === null ? (null) : (<div className="swipeButtons">
                            <IconButton onClick={() => swipe('left')} style={{height: '48px', width: '48px',
                                minHeight: '100px', marginLeft: '4vw', minWidth: '100px'}} className="swipeButtons__left">
                                <CloseIcon style={{color: 'black', minHeight:'36px', minWidth: '36px'}} fontSize="large" />
                            </IconButton>

                            <IconButton onClick={() => swipe('right')} style={{height: '48px', width: '48px', marginRight: '4vw',
                                marginLeft: '4vw', minWidth: '100px', minHeight: '100px'}} className="swipeButtons__right">
                                <FavoriteIcon style={{color: 'rgb(231, 73, 149)', minHeight:'36px', minWidth: '36px'}} fontSize="large" />
                            </IconButton>

                        </div>)}

                    </div>)}

            </div>
        );

    }

    }

function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString * 1000);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
