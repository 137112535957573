import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import {SwipeCards} from "../../components/SwipeCards";
import "./App.css";
import {Routes, Route, BrowserRouter as Router, Navigate} from 'react-router-dom';
import Chats from "../chats/Chats";
import ChatScreen from "../chats/ChatScreen";
import {Account} from "../account";

import {AuthListener} from "../../utils/authListener";
import {DataListener} from "../../utils/dataListener";
import {Matched} from "../../utils/matchedListener";
import {ProtectedRoute} from "../../utils/protectedRoute";
import {Provider, useSelector} from "react-redux";

import {Login} from "../login";
import {Setup} from "../setup";

import {FlowControl} from "../../utils/flowControl";

import store from "../../redux/store";
import {Profile} from "../setup/profile/profile";
import {Interests} from "../setup/interests";
import {Location} from "../setup/location";
import {AdminPage} from "../../admin";

export const App = () => {

    return (
        <div className="App">
            <Provider store={store}>
                <AuthListener>
                    <DataListener>
                        <Router>
                            <Routes>
                                <Route path={'login'} element={
                                    <Login/>
                                }/>

                                <Route path={'/admin'} element={<AdminPage/>}/>

                                <Route
                                    path="/"
                                    element={
                                        <ProtectedRoute>
                                            <FlowControl>
                                                <div style={{overflow: 'hidden'}}>
                                                    <Matched>
                                                        <Header/>
                                                        <SwipeCards/>
                                                    </Matched>
                                                </div>
                                            </FlowControl>

                                        </ProtectedRoute>
                                    }

                                />

                                <Route
                                    path="/account"
                                    element={
                                        <ProtectedRoute>
                                            <FlowControl>
                                                <Header/>
                                                <Account />
                                            </FlowControl>

                                        </ProtectedRoute>
                                    }

                                />

                                <Route
                                    path="/chat"
                                    element={
                                        <ProtectedRoute>
                                            <FlowControl>
                                                <div style={{overflow: 'hidden'}}>
                                                    <Header backButton="/" />
                                                    <Chats />
                                                </div>
                                            </FlowControl>

                                        </ProtectedRoute>
                                    }

                                />

                                <Route
                                    path="/chat/:chatId"
                                    element={
                                        <ProtectedRoute>
                                            <FlowControl>
                                                <div style={{overflow: 'hidden'}}>
                                                    <Header report={true} backButton="/chat" />
                                                    <ChatScreen/>
                                                </div>

                                            </FlowControl>

                                        </ProtectedRoute>
                                    }

                                />

                                <Route path={'/location'}
                                       element={
                                           <ProtectedRoute>
                                               <FlowControl>
                                                   <Setup path={'location'}/>
                                               </FlowControl>
                                           </ProtectedRoute>
                                       }
                                />

                                <Route path={'/location/edit'}
                                       element={
                                           <ProtectedRoute>
                                               <FlowControl>
                                                   <Header account={true}/>
                                                   <Location edit={true}/>
                                               </FlowControl>
                                           </ProtectedRoute>
                                       }
                                />

                                <Route path={'/profile'}
                                       element={
                                           <ProtectedRoute>
                                               <FlowControl>
                                                   <Setup path={'profile'}/>
                                               </FlowControl>
                                           </ProtectedRoute>
                                       }
                                />

                                <Route path={'/profile/edit'}
                                       element={
                                           <ProtectedRoute>
                                               <FlowControl>
                                                   <Header account={true}/>
                                                   <Profile edit={true}/>
                                               </FlowControl>
                                           </ProtectedRoute>
                                       }
                                />

                                <Route path={'/interests/edit'}
                                       element={
                                           <ProtectedRoute>
                                               <FlowControl>
                                                   <Header account={true}/>
                                                   <Interests edit={true}/>
                                               </FlowControl>
                                           </ProtectedRoute>
                                       }
                                />

                                <Route path={'/interests'}
                                       element={
                                           <ProtectedRoute>
                                               <FlowControl>
                                                   <Setup path={'interests'}/>
                                               </FlowControl>
                                           </ProtectedRoute>
                                       }
                                />

                                <Route path={'/work'}
                                       element={
                                           <ProtectedRoute>
                                               <FlowControl>
                                                   <Setup path={'work'}/>
                                               </FlowControl>
                                           </ProtectedRoute>
                                       }
                                />

                                <Route path={'/pay'}
                                       element={
                                           <ProtectedRoute>
                                               <FlowControl>
                                                   <Setup path={'pay'}/>
                                               </FlowControl>
                                           </ProtectedRoute>
                                       }
                                />

                                <Route path='*' element={<Navigate to={'/'} />}/>
                            </Routes>
                        </Router>

                    </DataListener>
                </AuthListener>
            </Provider>
        </div>
    );




}


