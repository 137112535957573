import {Location} from "./location";
import {Profile} from "./profile/profile";
import {Interests} from "./interests";
import {Work} from "./work";
import {Pay} from "../pay";


export const Setup = ({path}) => {

    const loadPage = () => {

        if (path === 'location') {
            return <Location/>
        } else if (path === 'profile') {
            return <Profile/>
        } else if (path === 'interests') {
            return <Interests/>
        } else if (path === 'work') {
            return <Work/>
        } else if (path === 'pay') {
            return <Pay/>
        }

    }


    return (<div className="d-flex flex-column flex-root" style={{paddingBottom: '50px'}}>
            {/*begin::Page*/}
            <div className="page d-flex flex-row flex-column-fluid">
                {/*begin::Aside*/}

                {/*end::Aside*/}
                {/*begin::Wrapper*/}
                <div className="wrapper d-flex flex-column flex-row-fluid"  style={{marginTop: '10vh'}} id="kt_wrapper">
                    {/*begin::Header*/}
                    <div id="kt_header" className="header">
                        {/*begin::Container*/}
                        <div className="container d-flex flex-stack flex-wrap gap-2" id="kt_header_container">
                            {/*begin::Page title*/}
                            <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}">
                                {/*begin::Heading*/}
                                <h1 className="d-flex flex-column text-dark fw-bold my-0 fs-1">Setup your profile</h1>
                                {/*end::Heading*/}
                                {/*begin::Breadcrumb*/}

                                {/*end::Breadcrumb*/}
                            </div>
                            {/*end::Page title=*/}
                            {/*begin::Wrapper*/}
                            {/*end::Wrapper*/}
                            {/*begin::Topbar*/}

                            {/*end::Topbar*/}
                        </div>
                        {/*end::Container*/}
                    </div>
                    {/*end::Header*/}
                    {/*begin::Content*/}
                    <div className="content d-flex flex-column flex-column-fluid" style={{marginTop: '50px'}} id="kt_content">
                        {/*begin::Container*/}
                        <div className="container-xxl" id="kt_content_container">
                            {/*begin::Stepper*/}
                            <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid gap-10" id="kt_create_account_stepper" data-kt-stepper="true">
                                {/*begin::Aside*/}
                                <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px">
                                    {/*begin::Wrapper*/}
                                    <div className="card-body px-6 px-lg-10 px-xxl-15 py-20">
                                        {/*begin::Nav*/}
                                        <div className="stepper-nav">
                                            {/*begin::Step 1*/}
                                            <div className={path === 'location' ? ('stepper-item current') : ('stepper-item')} data-kt-stepper-element="nav">
                                                {/*begin::Wrapper*/}
                                                <div className="stepper-wrapper">
                                                    {/*begin::Icon*/}
                                                    <div className="stepper-icon w-40px h-40px">
                                                        <i className="stepper-check fas fa-check" />
                                                        <span className="stepper-number">1</span>
                                                    </div>
                                                    {/*end::Icon*/}
                                                    {/*begin::Label*/}
                                                    <div className="stepper-label">
                                                        <h3 className="stepper-title">Location</h3>
                                                        <div className="stepper-desc fw-semibold">Find users closest to you</div>
                                                    </div>
                                                    {/*end::Label*/}
                                                </div>
                                                {/*end::Wrapper*/}
                                                {/*begin::Line*/}
                                                <div className="stepper-line h-40px" />
                                                {/*end::Line*/}
                                            </div>
                                            {/*end::Step 1*/}
                                            {/*begin::Step 2*/}
                                            <div className={path === 'profile' ? ('stepper-item current') : ('stepper-item')} data-kt-stepper-element="nav">
                                                {/*begin::Wrapper*/}
                                                <div className="stepper-wrapper">
                                                    {/*begin::Icon*/}
                                                    <div className="stepper-icon w-40px h-40px">
                                                        <i className="stepper-check fas fa-check" />
                                                        <span className="stepper-number">2</span>
                                                    </div>
                                                    {/*end::Icon*/}
                                                    {/*begin::Label*/}
                                                    <div className="stepper-label">
                                                        <h3 className="stepper-title">Profile</h3>
                                                        <div className="stepper-desc fw-semibold">Add your profile picture and bio</div>
                                                    </div>
                                                    {/*end::Label*/}
                                                </div>
                                                {/*end::Wrapper*/}
                                                {/*begin::Line*/}
                                                <div className="stepper-line h-40px" />
                                                {/*end::Line*/}
                                            </div>
                                            {/*end::Step 2*/}
                                            {/*begin::Step 3*/}
                                            <div className={path === 'interests' ? ('stepper-item current') : ('stepper-item')} data-kt-stepper-element="nav">
                                                {/*begin::Wrapper*/}
                                                <div className="stepper-wrapper">
                                                    {/*begin::Icon*/}
                                                    <div className="stepper-icon w-40px h-40px">
                                                        <i className="stepper-check fas fa-check" />
                                                        <span className="stepper-number">3</span>
                                                    </div>
                                                    {/*end::Icon*/}
                                                    {/*begin::Label*/}
                                                    <div className="stepper-label">
                                                        <h3 className="stepper-title">Interests / Hobbies</h3>
                                                        <div className="stepper-desc fw-semibold">Tell future dates more about you</div>
                                                    </div>
                                                    {/*end::Label*/}
                                                </div>
                                                {/*end::Wrapper*/}
                                                {/*begin::Line*/}
                                                <div className="stepper-line h-40px" />
                                                {/*end::Line*/}
                                            </div>

                                            <div className={path === 'work' ? ('stepper-item current') : ('stepper-item')} data-kt-stepper-element="nav">
                                                {/*begin::Wrapper*/}
                                                <div className="stepper-wrapper">
                                                    {/*begin::Icon*/}
                                                    <div className="stepper-icon w-40px h-40px">
                                                        <i className="stepper-check fas fa-check" />
                                                        <span className="stepper-number">4</span>
                                                    </div>
                                                    {/*end::Icon*/}
                                                    {/*begin::Label*/}
                                                    <div className="stepper-label">
                                                        <h3 className="stepper-title">Work / Education</h3>
                                                        <div className="stepper-desc fw-semibold">Describe your professional life</div>
                                                    </div>
                                                    {/*end::Label*/}
                                                </div>
                                                {/*end::Wrapper*/}
                                                {/*begin::Line*/}
                                                <div className="stepper-line h-40px" />
                                                {/*end::Line*/}
                                            </div>
                                            {/*end::Step 3*/}
                                            {/*begin::Step 4*/}
                                            <div className={path === 'pay' ? ('stepper-item current ') : ('stepper-item')} data-kt-stepper-element="nav">
                                                {/*begin::Wrapper*/}
                                                <div className="stepper-wrapper">
                                                    {/*begin::Icon*/}
                                                    <div className="stepper-icon w-40px h-40px">
                                                        <i className="stepper-check fas fa-check" />
                                                        <span className="stepper-number">5</span>
                                                    </div>
                                                    {/*end::Icon*/}
                                                    {/*begin::Label*/}
                                                    <div className="stepper-label">
                                                        <h3 className="stepper-title">Payment</h3>
                                                        <div className="stepper-desc fw-semibold">Subscribe to start using Guursamo</div>
                                                    </div>
                                                    {/*end::Label*/}
                                                </div>
                                                {/*end::Wrapper*/}
                                                {/*begin::Line*/}

                                                {/*end::Line*/}
                                            </div>
                                            {/*end::Step 4*/}
                                            {/*begin::Step 5*/}

                                            {/*end::Step 5*/}
                                        </div>
                                        {/*end::Nav*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                </div>
                                {/*begin::Aside*/}
                                {/*begin::Content*/}
                                <div className="card d-flex flex-row-fluid flex-center">
                                    {/*begin::Form*/}
                                    <div className="card-body py-20 w-100 mw-xl-700px px-9 fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate" id="kt_create_account_form">
                                        {/*begin::Step 1*/}
                                        <div className="current" data-kt-stepper-element="content">
                                            {/*begin::Wrapper*/}
                                            <div className="w-100">
                                                {/*begin::Heading*/}

                                                {/*end::Heading*/}
                                                {/*begin::Input group*/}
                                                <div className="fv-row fv-plugins-icon-container">
                                                    {/*begin::Row*/}
                                                    <div className="row">
                                                        {loadPage()}
                                                    </div>
                                                    {/*end::Row*/}
                                                </div>
                                                {/*end::Input group*/}
                                            </div>
                                            {/*end::Wrapper*/}
                                        </div>

                                        {/*end::Actions*/}
                                    </div>
                                    {/*end::Form*/}
                                </div>
                                {/*end::Content*/}
                            </div>
                            {/*end::Stepper*/}
                        </div>
                        {/*end::Container*/}
                    </div>
                    {/*end::Content*/}
                    {/*begin::Footer*/}

                    {/*end::Footer*/}
                </div>
                {/*end::Wrapper*/}
            </div>
            {/*end::Page*/}
        </div>
    )

}
