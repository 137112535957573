import React, { useState, useEffect } from 'react';
import './style.css'
import { auth } from "../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import PhoneModal from './PhoneModal';


export const Pay = () => {
    let [message, setMessage] = useState('');
    let [success, setSuccess] = useState(false);
    let [sessionId, setSessionId] = useState('');
    let [paymentMethod, setPaymentMethod] = useState('');
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const Message = ({ message }) => (
        <section>
            <p style={{ textAlign: 'center' }}>{message}</p>
            <button id="checkout-and-portal-button" style={{ height: '80px' }} onClick={() => window.location.replace('/')}>
                Start again
            </button>
        </section>
    );


    const ProductDisplay = () => (
        <section style={{ height: 'auto', minHeight: '270px' }}>

            <div style={{ margin: 'auto', padding: '20px', textAlign: 'center' }}>
                <span style={{ textAlign: 'center', fontSize: '20px' }}>
                    Gain Access to users on Guursamo<br /> the only Somali Marriage app
                </span>
            </div>

            <span style={{
                textAlign: 'left', fontSize: '18px', fontWeight: '600', margin: "20px 0px"
            }}>
                Select payment method to continue
            </span>

            <div className={'payment-option ' + (paymentMethod === 'stripe' ? "active" : "")} onClick={() => setPaymentMethod('stripe')}>
                <img src='/assets/icons/payment-option.jpg' alt='stripe' />
                <span>Debit/Credit Card<br />
                    <span style={{ fontSize: '12px', fontWeight: '400', color: "initial" }}>($9.99/month)</span>
                </span>
            </div>

            <div className={'payment-option ' + (paymentMethod === 'waafipay' ? "active" : "")} onClick={() => setPaymentMethod('waafipay')}>
                <img src='https://developer.waafipay.net/assets/images/logos.png' alt='waafipay' />
                <span>WaafiPay<br />
                    <span style={{ fontSize: '12px', fontWeight: '400', color: "initial" }}>($5/month)</span>
                </span>
            </div>

            {loading ? (<div className={'p-5'} style={{ margin: 'auto' }}>

                <span className="spinner-border spinner-border-m align-middle" style={{
                    display: 'block', margin: 'auto'
                }} />
            </div>) : (<button style={{ height: '45px' }} id="checkout-and-portal-button" onClick={() => continuePayment()}>
                Continue to payment
            </button>)}
        </section>
    );

    const SuccessDisplay = () => {
        return (
            <section style={{ height: '200px' }}>
                <div className="product Box-root">
                    <div className="description Box-root" style={{ textAlign: 'center', padding: '40px' }}>
                        <h3>Subscription Successful!</h3>
                        <h4 style={{ marginTop: '15px' }}>Please wait briefly while we activate your account</h4>
                        <span className="spinner-border spinner-border-m align-middle" style={{
                            display: 'block', margin: 'auto'
                        }} />
                    </div>
                </div>

            </section>
        );
    };

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
        }

        if (query.get('canceled')) {
            setSuccess(false);
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, [sessionId]);

    const continuePayment = () => {
        if (paymentMethod === '') {
            alert('Please select a payment method')
            return;
        }
        if (paymentMethod === 'stripe') {
            return payApi();
        }
        if (paymentMethod === 'waafipay') {
            return payWithWaafi();
        }
    }

    const payWithWaafi = () => {
        setIsPhoneModalOpen(true)
    }

    const payApi = () => {

        setLoading(true)

        auth.currentUser.getIdToken().then((token) => {

            const body = {
                'Authorization': `Bearer ${token}`
            }
            const base_url = process.env.REACT_APP_BASE_URL
            console.log(base_url)

            fetch(`${base_url}/pay`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        window.location.replace(data['checkout_url'])
                    }).catch((error) => {
                        if ('Message' in error.data) {
                            alert(error.data['Message']);
                            setLoading(false);
                            return;
                        }
                        alert('Error connecting to payment checkout, please try again')
                        setLoading(false)
                    })
                } else {
                    alert('Payment gateway failed, please try again')
                    setLoading(false)
                }

            }).catch(() => {
                alert('Error connecting to payment checkout, please try again')
                setLoading(false)
            });


        }).catch(() => {
            alert('Error connecting to payment checkout, please try again')
            setLoading(false)
        })
    };

    const submitPhone = (phone) => {
        if (phone === '') {
            alert('Please enter a valid phone number')
            return;
        }
        setLoading(true);
        setIsPhoneModalOpen(false);
        const body = {}
        auth.currentUser.getIdToken().then((token) => {
            body['Authorization'] = `Bearer ${token}`;
            body['phone'] = phone;
            const base_url = process.env.REACT_APP_BASE_URL
            fetch(`${base_url}/waafipay-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        if ('Error' in data) {
                            alert(data['Error'])
                            setLoading(false)
                            return;
                        }
                        let status = data['status']
                        if (status === 'success') {
                            window.location.replace(`/pay?success=true&session_id=${data['reference_id']}`)
                        } else {
                            alert('Payment gateway failed, please try again')
                            setLoading(false)
                        }
                    }).catch(() => {
                        alert('Error connecting to payment checkout, please try again')
                        setLoading(false)
                    })
                } else {
                    alert('Payment gateway failed, please try again')
                    setLoading(false)
                }

            }).catch(() => {
                alert('Error connecting to payment checkout, please try again')
                setLoading(false)
            });
        }).catch(() => {
            alert('Error connecting to payment checkout, please try again!')
            setLoading(false)
        })
    }


    if (!success && message === '') {
        return <><ProductDisplay /> {isPhoneModalOpen && <PhoneModal isModalOpen={isPhoneModalOpen} setIsModalOpen={setIsPhoneModalOpen} submitPhone={submitPhone} />}</>

    } else if (success && sessionId !== '') {
        return <SuccessDisplay sessionId={sessionId} />;
    } else {
        return <Message message={message} />;
    }
}
