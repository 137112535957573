import React, {useEffect, useState} from "react";
import Avatar from "@mui/material/Avatar";
import "./ChatScreen.css";
import {collection, doc, getDoc, onSnapshot, orderBy, query, addDoc, updateDoc} from "firebase/firestore";
import {auth, db} from "../../firebase/firebase";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ProfileComponent} from "../../components/profile";
import {setPerson, setShown} from "../../redux/slices/profile";
import ReactTimeAgo from "react-time-ago";

const ChatScreen = () => {
  const [input, setInput] = useState("");

  const dispatch = useDispatch()

  const [chatData, setChatData] = useState([])
  const [personData, setPersonData] = useState(null)
  const [loading, setLoading] = useState(true)
  let { chatId } = useParams();

  const [height, setHeight] = useState(window.innerHeight)

  const updateHeight = () => {

    setHeight(window.innerHeight)

  }

  useEffect(() => {


    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }

  }, [])

  useEffect( () => {

    const groupRef = collection(db, `groups/${chatId}/messages`)
    const q = query(groupRef,
        orderBy('sentAt', 'asc'))

    const docRef = doc(db, "groups", chatId);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const personId = docSnap.data()['members'].filter((id1) => id1 !== auth.currentUser.uid)[0]
        setPersonData(docSnap.data()[personId])
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          setChatData([])
          querySnapshot.forEach((docData) => {
            setChatData(prevState => [...prevState, docData.data()])
          });
          setLoading(false)
          //  Here scroll down
          var objDiv = document.getElementById("chatScreen");
          objDiv.scrollTop = objDiv.scrollHeight;

        })

        return () => {
          unsubscribe()
        }
      } else {

        console.log("No such document!");
      }
    })

    return () => {
      setChatData([])
      setPersonData(null)
    }


  }, [])

  const personShown = useSelector(state => state.profile.isShown)

  const showProfile = (person) => {

    dispatch(setPerson(person))
    dispatch(setShown(true))
  }

  const sendMessage = () => {

    addDoc(collection(db, `groups/${chatId}/messages`), {
      message: input,
      sentBy: auth.currentUser.uid,
      sentAt: Math.floor(Date.now() / 1000)
    }).then(() => {
      updateDoc(doc(db, `groups/${chatId}`), {
          recentMessage: {
            'message': input,
            'sentAt': Math.floor(Date.now() / 1000)
          }
      }).then(() => {
        setInput('')
      })
    })

  }

  if (personShown) {
    return <ProfileComponent/>
  } else {

    return (
        <div>
          <p  className="chatScreen__timestamp">
            YOU MATCHED WITH {personData !== null ?
              personData['profile']['display_name'].toUpperCase() : ''} ON {chatData.length > 0 ?
              new Date(chatData[0]['sentAt'] * 1000).toLocaleDateString("en-US") : ''}
            <button style={{maxWidth: '120px', marginLeft: '25px',
              backgroundColor: 'white', color: 'black'}} onClick={() => showProfile(personData)}>View profile</button>
          </p>
          <div className="chatScreen" id={'chatScreen'} style={{maxHeight: `${height-230}px`}}>

            {chatData.map((message) =>
                message.sentBy !== auth.currentUser.uid ? (
                    message.sentBy !== 'admin' ? (
                        <div className="chatScreen__message">
                          <Avatar
                              className="chatScreen__image"
                              src={personData.profile.profileImg}
                          />
                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{flex: 5}}>
                              <p className="chatScreen__text">{message.message}</p>
                            </div>
                            <div style={{flex: 1, alignSelf: 'flex-start'}}>
                      <span style={{color: 'gray'}}>
                      <ReactTimeAgo date={new Date(message['sentAt'] * 1000)}/>
                    </span>
                            </div>
                          </div>
                        </div>
                    ) : (null)

                ) : (<div className="chatScreen__message ownMessage">
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{flex: 5}}>
                      <p className="chatScreen__owntext">{message.message}</p>
                    </div>
                    <div style={{flex: 1, alignSelf: 'flex-end'}}>
                      <span style={{color: 'gray'}}>
                      <ReactTimeAgo date={new Date(message['sentAt'] * 1000)}/>
                    </span>
                    </div>
                  </div>

                </div>)
            )}
            <div className="chatScreen__form">
        <textarea
            className="chatScreen__input"
            value={input}
            placeholder={'Type your message here'}
            onChange={(e) => setInput(e.target.value)}
        />
              <button
                  onClick={() => sendMessage()}
                  className="chatScreen__button"
                  disabled={input === '' ? (true) : (false)}
              >
                SEND
              </button>
            </div>
          </div>
        </div>

    );
  }
};

export default ChatScreen;
